<template>
  <v-container>
    <v-snackbar
      app
      top
      v-model="snackbar"
      :color="snackbarColor"
      :multi-line="true"
      :timeout="timeout"
    >
      {{ errorText }}
    </v-snackbar>
    <v-dialog v-model="snoozeDialog" width="500">
      <v-card elevation="8">
        <v-card-title><span>Imposta data</span></v-card-title>
        <v-card-text>
          <v-date-picker
            v-model="snoozeDate"
            locale="it-it"
            :min="new Date().toISOString()"
          ></v-date-picker>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="ma-2"
            color="primary"
            :disabled="!snoozeDate"
            @click="
              snooze(purchase.ID);
              snoozeDialog = false;
            "
            >OK</v-btn
          >
          <v-btn class="ma-2" color="primary" @click="snoozeDialog = false"
            >Annulla</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      hide-overlay
      no-click-animation
      persistent
      v-model="addChargeDialog"
      width="500"
    >
      <v-card elevation="8">
        <v-card-title style="background-color: #003366; color: white"
          >Associa buono di carico</v-card-title
        >
        <v-card-text>
          <v-text-field v-model="chargeYear" label="Anno buono di carico">
          </v-text-field
          ><br />
          <v-text-field v-model="chargeNumber" label="Numero buono di carico">
          </v-text-field
          ><br />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :disabled="invalidCharge"
            @click="addCharge(chargeYear, chargeNumber)"
            >OK</v-btn
          >
          <v-btn color="primary" @click="addChargeDialog = false"
            >Annulla</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      hide-overlay
      no-click-animation
      persistent
      v-model="updateDialog"
      width="500"
    >
      <v-card elevation="8">
        <v-card-title style="background-color: #003366"
          ><span v-if="sendEvent" style="color: white">Conferma</span
          ><span v-else style="color: white">Allega o commenta</span
          ><v-spacer></v-spacer
          ><v-btn icon @click="updateDialog = false"
            ><v-icon style="color: white">mdi-close-thick</v-icon></v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-file-input
            ref="imageUpload"
            v-model="currentFiles"
            :clearable="false"
            multiple
            chips
            truncate-length="15"
            accept="*/*"
            label="Aggiungi allegati"
          >
            <template v-slot:selection="{ index, text }">
              <v-chip
                close
                color="secondary"
                @click:close="deleteChip(index, text)"
              >
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
          <!-- <v-row v-for="(thisfile, fileindex) of currentFiles" :key="fileindex">
            <v-col cols="10">{{ thisfile.name }}</v-col>
            <v-col cols="2">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" @click="currentFiles.splice(fileindex,1)">
                    <v-icon small>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
                <span>Rimuovi</span>
              </v-tooltip>
            </v-col>
          </v-row> -->
          <v-textarea v-model="comment" label="Note"></v-textarea>
        </v-card-text>
        <v-card class="mx-2">
          <v-card-text
            class="pa-0"
            v-if="
              sendEvent &&
              myFSM.states[myFSM.states[currentState].on[sendEvent].target].meta
                .groupManager &&
              checkIsUserManager(
                myFSM.states[myFSM.states[currentState].on[sendEvent].target]
                  .meta.groupManager
              ) &&
              isUserManager
            "
          >
            <v-select
              :items="currentGroupUsers"
              filled
              v-model="assignee"
              label="Assegna pratica"
              item-value="MATRICOLA"
              persistent-hint
              hint="Verrà inviata una notifica automatica all'assegnatario della
            pratica"
            >
              <template slot="selection" slot-scope="data">
                {{ data.item.COGNOME }} {{ data.item.NOME }}
              </template>
              <template slot="item" slot-scope="data">
                {{ data.item.COGNOME }} {{ data.item.NOME }}
              </template>
            </v-select>
          </v-card-text>
        </v-card>
        <br />
        <v-card-text>
          <v-row dense>
            <v-col cols="10">
              <input
                type="checkbox"
                dense
                v-model="allGroupUsers"
                @change="evaluateAllGroupUsers()"
              />
              {{ groupNotifyLabel }}
            </v-col>
          </v-row>
          <v-row v-for="user of currentGroupUsers" :key="user.MATRICOLA" dense>
            <v-spacer></v-spacer>
            <v-col cols="10">
              <input
                type="checkbox"
                dense
                v-model="selectedGroupUsers"
                :value="{
                  matricola: user.MATRICOLA,
                  fullname: user.COGNOME + ' ' + user.NOME,
                }"
                :label="user.COGNOME + ' ' + user.NOME"
              />
              Notifica
              {{ user.COGNOME + " " + user.NOME }}
            </v-col>
          </v-row>
          <v-autocomplete
            v-model="selectedExtUser"
            :items="allOperators"
            @change="addExtUser"
            label="Altri operatori da notificare..."
            :filter="autocompleteFilter"
          >
            <template v-slot:item="data">
              Notifica {{ data.item.COGNOME }} {{ data.item.NOME }}
            </template>
            <template v-slot:selection="data">
              Notifica {{ data.item.COGNOME }} {{ data.item.NOME }}
            </template>
          </v-autocomplete>
          <v-row
            v-for="user of currentExtGroupUsers"
            :key="user.MATRICOLA"
            dense
          >
            <v-spacer></v-spacer>
            <v-col cols="10">
              <input
                type="checkbox"
                dense
                v-model="selectedExtUsers"
                :value="{
                  matricola: user.MATRICOLA,
                  fullname: user.COGNOME + ' ' + user.NOME,
                }"
                :label="user.COGNOME + ' ' + user.NOME"
              />
              Notifica
              {{ user.COGNOME + " " + user.NOME }}
            </v-col>
          </v-row>
          <br />
          <v-row dense>
            <v-col>
              <input
                type="checkbox"
                dense
                v-if="myFSM && currentState"
                v-model="notifyUsers"
                :label="userNotifyLabel"
              />
              {{ userNotifyLabel }}
            </v-col>
          </v-row>
          <br />
        </v-card-text>
        <v-card-text
          v-if="
            sendEvent &&
            (myFSM.states[currentState].meta
              .insertCIG)
          "
        >
        <v-text-field
            label="CIG"
            placeholder="inserire CIG"
            v-model="purchase.CIG"
          ></v-text-field>
        </v-card-text>
        <v-card-text
          v-if="
            sendEvent &&
            myFSM.states[myFSM.states[currentState].on[sendEvent].target].meta
              .timer
          "
        >
          {{
            myFSM.states[myFSM.states[currentState].on[sendEvent].target].meta
              .timer
          }}:
          <v-btn icon @click="dateDialog = true"
            ><v-icon>mdi-calendar-blank</v-icon></v-btn
          >{{ timerDate ? formatDate(timerDate) : "Timer non impostato" }}
          <v-dialog v-model="dateDialog" width="300">
            <v-date-picker
              v-model="timerDate"
              @change="dateDialog = false"
              locale="it-it"
              :min="new Date().toISOString()"
            ></v-date-picker>
          </v-dialog>
        </v-card-text>
        <v-card-title v-if="sendEvent"
          >{{ getEventText(sendEvent) }}<br />Confermi?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- 1 {{sendEvent}}<br />
          2 {{assignee}}<br />
          3 {{financialAssignee}}<br /> -->
          <div
            style="color: orange"
            class="caption"
            v-if="
              sendEvent === 'jobAssigned' && !assignee && !financialAssignee
            "
          >
            L'incarico non è assegnato
          </div>
          <v-spacer></v-spacer>
          <v-btn
            class="ma-2"
            color="primary"
            :disabled="needEvent()"
            @click="updatePurchase(purchase.ID, sendEvent, sendMethod)"
            >OK</v-btn
          >
          <v-btn
            class="ma-2"
            color="primary"
            @click="
              updateDialog = false;
              timerDate = null;
            "
            >Annulla</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      hide-overlay
      no-click-animation
      persistent
      v-if="globalStep"
      v-model="detailsDialog"
      width="800"
    >
      <v-card elevation="8">
        <v-card-title style="background-color: #003366"
          ><span style="color: white">Dettagli</span><v-spacer></v-spacer
          ><v-btn style="color: white" icon @click="detailsDialog = false"
            ><v-icon style="color: white">mdi-close-thick</v-icon></v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-timeline dense>
            <v-timeline-item
              small
              v-for="(inside, i) in globalStep.insideSteps.reverse()"
              :key="'ciccio' + i"
              :color="myFSM.states[inside.state].meta.color"
            >
              <template v-slot:icon>
                <v-icon small dark>mdi-arrow-up</v-icon>
              </template>
              <v-card elevation="4">
                <v-card-text>
                  <span
                    >{{
                      inside.User.LOCAL_COGNOME
                        ? inside.User.LOCAL_COGNOME
                        : inside.User.COGNOME
                    }}
                    {{
                      inside.User.LOCAL_NOME
                        ? inside.User.LOCAL_NOME
                        : inside.User.NOME
                    }}</span
                  >:<br />{{ formatStep(inside) }}<br />
                  <span
                    v-if="
                      inside.comment &&
                      inside.comment !== null &&
                      inside.comment !== ''
                    "
                    >Note: {{ inside.comment }}</span
                  ><br />
                  <span
                    v-if="
                      inside.financialAssignee &&
                      inside.financialAssignee !== null &&
                      inside.financialAssignee !== ''
                    "
                    >Assegnato a: {{ inside.financialAssignee.COGNOME }}
                    {{ inside.financialAssignee.NOME }}</span
                  ><br />
                  <v-row dense>
                    <v-col cols="2">Notificato:</v-col>
                    <v-col cols="10"
                      >Richiedente/i
                      <v-icon small color="green" v-if="inside.notifiedUsers"
                        >mdi-check-bold</v-icon
                      ><v-icon small color="red" v-else>mdi-close-thick</v-icon>
                      <br />
                      Operatori
                      <v-icon
                        small
                        color="green"
                        v-if="JSON.parse(inside.notifiedGroupUsers).length > 0"
                        >mdi-check-bold</v-icon
                      ><span
                        v-if="JSON.parse(inside.notifiedGroupUsers).length > 0"
                        >{{
                          notifiedGroupUsersList(
                            JSON.parse(inside.notifiedGroupUsers)
                          )
                        }}</span
                      ><v-icon small color="red" v-else>mdi-close-thick</v-icon
                      ><br />
                    </v-col>
                  </v-row>
                  <span v-if="inside.attachment"
                    >Allegato:
                    <a
                      v-if="!attachmentDeleted(inside.attachment)"
                      @click="downloadAttachment(inside.attachment)"
                      >{{ attachmentFilename(inside.attachment) }}</a
                    ><span
                      v-else
                      class="text-decoration-line-through text--disabled"
                      >{{ attachmentFilename(inside.attachment) }}</span
                    ></span
                  ><br />
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="detailsDialog = false">Chiudi</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row v-if="loading">
      <v-spacer></v-spacer>
      <v-col cols="1"
        ><v-progress-circular
          indeterminate
          color="primary"
          :size="70"
          :width="7"
      /></v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row v-else-if="error">
      <v-spacer></v-spacer>
      <v-col cols="6">{{ errorText }}</v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row v-else>
      <v-col cols="12" lg="6">
        <v-card
          outlined
          elevation="8"
          v-if="myFSM"
          :disabled="loadingAttachment"
          :color="myFSM.states[currentState].meta.color"
        >
          <span
            class="white--text px-2 truncated caption text-uppercase"
            v-if="!purchase.closed"
            >Attualmente assegnato a:
            {{ myFSM.states[currentState].meta.responsible
            }}<span
              v-if="
                financialAssignee && myFSM.states[currentState].meta.financial
              "
              >,
              <strong
                >{{ financialAssignee.COGNOME }}
                {{ financialAssignee.NOME }}</strong
              ></span
            ></span
          >
          <v-card
            class="mt-0"
            outlined
            :color="myFSM.states[currentState].meta.color"
          >
            <v-card>
              <v-card-title>Richiesta: {{ purchaseDescription }} </v-card-title>
              <v-card-text>
                <strong>
                  ID procedura acquisto:
                  {{ purchase.ID }}<br />
                  Stato attuale:
                  {{ myFSM.states[currentState].meta.fullStateName }}<br />
                  Utente (Richiedente):
                  <span v-for="(user, j) of purchase.Users" :key="j"
                    >{{ user.COGNOME }} {{ user.NOME
                    }}<span v-if="j !== purchase.Users.length - 1"
                      >,
                    </span> </span
                  ><br />
                  <span v-if="currentMethod"
                    >Tipologia di acquisto: {{ currentMethodText }}<br /></span>
                  <span v-if="purchase.CIG"
                    >CIG: {{ purchase.CIG }}<br /></span>
                    </strong>

                <i
                  v-if="
                    currentHistory.memoID &&
                    currentHistory.memoDate &&
                    currentHistory.memoDate <= perfectDateNow()
                  "
                  >&Egrave; stato inviato ({{
                    formatDate(currentHistory.memoDate)
                  }}) un memo per questo stato
                  <v-btn
                    v-if="userRole === 'admin' || userRole === 'operator'"
                    @click="
                      snoozeDate = null;
                      snoozeDialog = true;
                      snoozeID = currentHistory.memoID;
                    "
                    color="primary"
                    >Ripeti</v-btn
                  ></i
                >
                <i v-else-if="currentHistory.memoID && currentHistory.memoDate"
                  >Sarà inviato un memo per questo stato ({{
                    formatDate(currentHistory.memoDate)
                  }})</i
                >

                <v-row>
                  <v-col cols="6">
                    <v-select
                      :items="currentGroupUsers"
                      v-model="assignee"
                      label="Assegna pratica"
                      item-value="MATRICOLA"
                      clearable
                      v-if="
                        myFSM.states[currentState].meta.groupManager &&
                        checkIsUserManager(
                          myFSM.states[currentState].meta.groupManager
                        ) &&
                        isUserManager
                      "
                    >
                      <template slot="selection" slot-scope="data">
                        {{ data.item.COGNOME }} {{ data.item.NOME }}
                      </template>
                      <template slot="item" slot-scope="data">
                        {{ data.item.COGNOME }} {{ data.item.NOME }}
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-btn
                      v-if="
                        myFSM.states[currentState].meta.groupManager &&
                        checkIsUserManager(
                          myFSM.states[currentState].meta.groupManager
                        ) &&
                        isUserManager
                      "
                      class="ma-2"
                      color="primary"
                      :disabled="!assignee"
                      @click="
                        attFilename = null;
                        attFilenames = [];
                        currentFile = '';
                        currentFiles = [];
                        comment = null;
                        sendEvent = null;
                        sendMethod = null;
                        notifyUsers = true;
                        snoozeDate = null;
                        timerDate = null;
                        updatePurchase(purchase.ID, null, currentMethod);
                      "
                      >Assegna</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card
              v-if="userRole === 'admin' || userRole === 'operator'"
              class="mt-2"
            >
              <v-card-text class="attachmentlist">
                <v-expansion-panels v-if="attachments.length > 0">
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <v-row>
                        <v-col cols="3" align-self="center"
                          ><strong
                            >Allegati:<v-chip small>{{
                              attachments.length
                            }}</v-chip></strong
                          ></v-col
                        >
                        <v-col cols="2" align-self="center"
                          ><strong>inserito:</strong></v-col
                        >
                        <v-col cols="3" align-self="center"
                          ><strong>da:</strong></v-col
                        >
                        <v-col cols="3" align-self="center"
                          ><strong>nella fase:</strong></v-col
                        >
                        <v-col
                          cols="1"
                          v-if="userRole === 'admin' || userRole === 'operator'"
                        ></v-col>
                      </v-row>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content
                      v-for="attachment of attachments"
                      :key="attachment.id"
                      dense
                      :class="
                        attachment.deleted
                          ? 'text-decoration-line-through text--disabled'
                          : ''
                      "
                    >
                      <v-row
                        :class="
                          attachment.starred
                            ? 'font-weight-bold grey lighten-3'
                            : ''
                        "
                      >
                        <v-col cols="3"
                          >-&nbsp;<a
                            v-if="!attachment.deleted"
                            @click="downloadAttachment(attachment.id)"
                            >{{ attachment.ORIGINAL_FILENAME }}</a
                          ><span v-else>{{
                            attachment.ORIGINAL_FILENAME
                          }}</span></v-col
                        >
                        <v-col cols="2" v-if="attachment.state">{{
                          formatTime(attachment.state.date)
                        }}</v-col>
                        <v-col cols="3" v-if="attachment.state && attachment.state.User"
                          >{{
                            attachment.state.User.LOCAL_COGNOME
                              ? attachment.state.User.LOCAL_COGNOME
                              : attachment.state.User.COGNOME
                          }}
                          {{
                            attachment.state.User.LOCAL_NOME
                              ? attachment.state.User.LOCAL_NOME
                              : attachment.state.User.NOME
                          }}</v-col
                        >
                        <v-col cols="3" v-else></v-col>
                        <v-col cols="2" v-if="attachment.state">{{
                          myFSM.states[attachment.state.state].meta
                            .fullStateName
                        }}</v-col>
                        <v-col
                          v-if="
                            (userRole === 'admin' || userRole === 'operator') &&
                            attachment.state
                          "
                          cols="2"
                          ><v-tooltip v-if="!attachment.deleted" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="
                                  deleteAttachment(attachment.id);
                                  attachment.deleted = 1;
                                "
                                ><v-icon color="primary" dark>
                                  mdi-trash-can-outline
                                </v-icon></v-btn
                              >
                            </template>
                            <span>Segna come rimosso</span> </v-tooltip
                          ><v-tooltip v-else top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="
                                  restoreAttachment(attachment.id);
                                  attachment.deleted = 0;
                                "
                                ><v-icon color="primary" dark>
                                  mdi-delete-restore
                                </v-icon></v-btn
                              >
                            </template>
                            <span>Segna come non rimosso</span> </v-tooltip
                          ><v-tooltip v-if="!attachment.starred" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="
                                  starAttachment(attachment.id);
                                  attachment.starred = 1;
                                "
                                ><v-icon color="primary" dark>
                                  mdi-star-outline
                                </v-icon></v-btn
                              >
                            </template>
                            <span>Segna come importante</span> </v-tooltip
                          ><v-tooltip v-else top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="
                                  unstarAttachment(attachment.id);
                                  attachment.starred = 0;
                                "
                                ><v-icon color="primary" dark>
                                  mdi-star-off-outline
                                </v-icon></v-btn
                              >
                            </template>
                            <span>Segna come non importante</span>
                          </v-tooltip>
                        </v-col>
                        <v-col cols="1" v-else-if="attachment.state"> </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card-text>
              <v-card-text>
                <br />
                <v-btn
                  v-if="
                    !purchase.closed &&
                    (userRole === 'admin' || userRole === 'operator')
                  "
                  color="primary"
                  @click="
                    attFilename = null;
                    attFilenames = [];
                    currentFile = null;
                    currentFiles = [];
                    comment = null;
                    sendEvent = null;
                    sendMethod = null;
                    updateDialog = true;
                    notifyUsers = true;
                    snoozeDate = null;
                    timerDate = null;
                    assignee = null;
                    groupNotifyLabel =
                      'Seleziona/deseleziona tutti (' +
                      myFSM.states[currentState].meta.responsibleGroup +
                      ')';
                    selectAllGroupUsers(currentState);
                  "
                  >Allega o commenta</v-btn
                >
              </v-card-text>
            </v-card>
            <v-card
              class="mt-2"
              v-if="
                ((userRole === 'admin' || userRole === 'operator') &&
                  myFSM.states[currentState].meta.labelize) ||
                purchase.Charges.length > 0
              "
            >
              <v-card-text>
                <strong v-if="myFSM.states[currentState].meta.labelize">
                  <span v-if="purchase.Charges.length > 0">
                    BUONI DI CARICO ASSOCIATI:<br />
                    <span v-for="charge of purchase.Charges" :key="charge.id">
                      <v-row dense>
                        <v-col cols="4" align-self="center"
                          >- Buono numero {{ charge.NUMBER }}, anno
                          {{ charge.YEAR }}</v-col
                        >
                        <v-col cols="2" align-self="center"
                          ><v-btn
                            v-if="
                              userRole === 'admin' || userRole === 'operator'
                            "
                            icon
                            @click="deleteCharge(charge.YEAR, charge.NUMBER)"
                            ><v-icon>mdi-trash-can-outline</v-icon></v-btn
                          ></v-col
                        >
                      </v-row>
                    </span>
                  </span>
                  <span v-else> NESSUN BUONO DI CARICO ASSOCIATO </span><br />
                  <span
                    @click="
                      chargeYear = '';
                      chargeNumber = '';
                      addChargeDialog = true;
                    "
                    ><v-btn
                      v-if="userRole === 'admin' || userRole === 'operator'"
                      color="primary"
                      >Associa buono di carico</v-btn
                    ><br
                  /></span>
                </strong>
                <v-expansion-panels v-if="assets.length > 0">
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <v-row>
                        <v-col cols="11" align-self="center"
                          ><strong
                            >Beni:<v-chip small>{{
                              assets.length
                            }}</v-chip></strong
                          ></v-col
                        >
                        <v-col cols="1" align-self="center"> </v-col>
                      </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      v-for="asset of assets"
                      :key="asset.ID"
                      dense
                    >
                      <v-row
                        :class="
                          asset.DT_BUONO_SCARICO
                            ? 'text-decoration-line-through text--disabled'
                            : ''
                        "
                      >
                        <v-col cols="11">
                          {{ asset.DS_BENE }}
                        </v-col>
                        <v-col cols="1">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <router-link
                                v-bind="attrs"
                                v-on="on"
                                :to="'/assets/' + asset.ID"
                              >
                                <v-icon small> mdi-eye-outline </v-icon>
                              </router-link>
                            </template>
                            <span>Visualizza</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card-text>
            </v-card>
          </v-card>
          <v-card
            v-if="userRole === 'admin' || userRole === 'operator'"
            class="mt-2"
          >
            <v-card-title v-if="!purchase.closed"
              >Avanzamento procedura:</v-card-title
            >
            <v-card-title v-else-if="purchase.closed"
              >Procedura di acquisto terminata</v-card-title
            >
            <v-card-text v-if="userRole === 'admin' || userRole === 'operator'">
              <div
                v-for="(button, index) in myFSM.states[currentState].meta
                  .selection"
                :key="'unuseful' + index"
              >
                <v-row>
                  <v-col cols="6" class="text-right">
                    {{ button.text }}: vai alla fase<br />&laquo;{{
                      myFSM.states[
                        myFSM.states[currentState].on[button.event].target
                      ].meta.fullStateName
                    }}&raquo;
                  </v-col>
                  <v-col cols="6">
                    <v-btn
                      large
                      dark
                      color="primary"
                      :disabled="updating"
                      @click="
                        timerDate = null;
                        attFilename = null;
                        attFilenames = [];
                        currentFile = null;
                        currentFiles = [];
                        comment = null;
                        sendMethod = button.method;
                        sendEvent = button.event;
                        notifyUsers = true;
                        updateDialog = true;
                        assignee = null;
                        removeAssignee = button.back ? true : false;
                        groupNotifyLabel =
                          'Seleziona/deseleziona tutti (' +
                          myFSM.states[
                            myFSM.states[currentState].on[button.event].target
                          ].meta.responsibleGroup +
                          ')';
                        selectAllGroupUsers(
                          myFSM.states[currentState].on[button.event].target
                        );
                      "
                      ><v-icon v-if="button.back"
                        >mdi-arrow-left-bottom-bold</v-icon
                      ><v-icon v-else>mdi-arrow-right-bold</v-icon></v-btn
                    >
                  </v-col>
                </v-row>
              </div>
              <div
                v-for="(button, k) in myFSM.states[currentState].meta.buttons"
                :key="k"
              >
                <v-row>
                  <v-col cols="6" class="text-right">
                    {{ button.text }}: vai alla fase<br />&laquo;{{
                      myFSM.states[
                        myFSM.states[currentState].on[
                          button.event ? button.event : computedEvent
                        ].target
                      ].meta.fullStateName
                    }}&raquo;
                  </v-col>
                  <v-col cols="6">
                    <v-btn
                      large
                      dark
                      color="primary"
                      :disabled="updating"
                      @click="
                        timerDate = null;
                        attFilename = null;
                        attFilenames = [];
                        currentFile = null;
                        currentFiles = [];
                        comment = null;
                        sendEvent = button.event ? button.event : computedEvent;
                        sendMethod = button.method;
                        notifyUsers = true;
                        updateDialog = true;
                        assignee = null;
                        removeAssignee = button.back ? true : false;
                        groupNotifyLabel =
                          'Seleziona/deseleziona tutti (' +
                          myFSM.states[
                            myFSM.states[currentState].on[
                              button.event ? button.event : computedEvent
                            ].target
                          ].meta.responsibleGroup +
                          ')';
                        selectAllGroupUsers(
                          myFSM.states[currentState].on[
                            button.event ? button.event : computedEvent
                          ].target
                        );
                      "
                      ><v-icon v-if="button.back"
                        >mdi-arrow-left-bottom-bold</v-icon
                      ><v-icon v-else>mdi-arrow-right-bold</v-icon></v-btn
                    >
                  </v-col>
                </v-row>
              </div>
              <br /><br />
            </v-card-text>
            <v-card-text v-else>
              <br />
              <br />
              <br />
            </v-card-text>
          </v-card>
        </v-card>
      </v-col>
      <v-col cols="12" lg="6">
        <v-card
          outlined
          elevation="8"
          :disabled="loadingAttachment"
          :color="myFSM.states[currentState].meta.color"
        >
          <v-card class="mt-2">
            <v-card-title>
              Timeline
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="toggleList()"
                    elevation="2"
                    icon
                    absolute
                    right
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="primary">mdi-format-list-bulleted</v-icon>
                  </v-btn>
                </template>
                <span>Cambia vista timeline</span>
              </v-tooltip>
            </v-card-title>

            <v-card-text v-if="!toggleToList">
              <v-timeline v-if="purchase" :reverse="false">
                <v-timeline-item
                  v-for="(step, l) in reducedReversedHistory"
                  :key="l"
                  :class="l % 2 ? '' : 'text-right'"
                  small
                  fill-dot
                  :color="myFSM.states[step.state].meta.color"
                >
                  <template v-slot:icon>
                    <v-icon medium dark>mdi-arrow-up</v-icon>
                  </template>
                  <template v-slot:opposite>
                    <span>{{ formatStep(step) }}</span
                    ><br />{{ getEventText(step.event) }}
                  </template>
                  <v-alert
                    :value="true"
                    :color="myFSM.states[step.state].meta.color"
                    class="white--text pa-1"
                  >
                    <strong>{{
                      myFSM.states[step.state].meta.fullStateName
                    }}</strong
                    ><br />
                    <strong>{{
                      myFSM.states[step.state].meta.responsible
                    }}</strong>
                    <br />
                    <br />
                    <v-btn
                      class="pa-1"
                      @click="
                        globalStep = step;
                        detailsDialog = true;
                      "
                      >Dettagli</v-btn
                    >
                  </v-alert>
                </v-timeline-item>
              </v-timeline>
            </v-card-text>
            <v-card-text v-else>
              <div v-for="(step, l) in reducedReversedHistory" :key="'b' + l">
                <center v-if="l !== 0">
                  <v-icon :color="myFSM.states[step.state].meta.color"
                    >mdi-arrow-up-bold</v-icon
                  >
                </center>
                <v-card
                  outlined
                  elevation="8"
                  class="mb-2"
                  :color="myFSM.states[step.state].meta.color"
                >
                  <v-card-title class="py-0 steptitle text-truncate">
                    {{ formatStep(step) }} - {{ getEventText(step.event) }} -
                    {{ myFSM.states[step.state].meta.responsible }}
                  </v-card-title>
                  <v-card-text>
                    <v-sheet
                      elevation="3"
                      class="pa-1"
                      v-for="(inside, i) in step.insideStepsReversed"
                      :key="'a' + i"
                    >
                      {{
                        inside.User.LOCAL_COGNOME
                          ? inside.User.LOCAL_COGNOME
                          : inside.User.COGNOME
                      }}

                      {{
                        inside.User.LOCAL_NOME
                          ? inside.User.LOCAL_NOME
                          : inside.User.NOME
                      }}
                      -
                      {{ formatStep(inside) }}

                      <strong
                        v-if="
                          inside.comment &&
                          inside.comment !== null &&
                          inside.comment !== ''
                        "
                        ><br />Note: {{ inside.comment }}</strong
                      >

                      <span
                        v-if="
                          inside.financialAssignee &&
                          inside.financialAssignee !== null &&
                          inside.financialAssignee !== ''
                        "
                        ><br />Assegnato a:
                        {{ inside.financialAssignee.COGNOME }}
                        {{ inside.financialAssignee.NOME }}</span
                      >
                      <br />
                      <span v-if="inside.attachment"
                        >Allegato:
                        <a
                          v-if="!attachmentDeleted(inside.attachment)"
                          @click="downloadAttachment(inside.attachment)"
                          >{{ attachmentFilename(inside.attachment) }}</a
                        ><span
                          v-else
                          class="text-decoration-line-through text--disabled"
                          >{{ attachmentFilename(inside.attachment) }}</span
                        ></span
                      >
                    </v-sheet>
                  </v-card-text>
                </v-card>
              </div>
            </v-card-text>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
const moment = require("moment-timezone");
import { mapState } from "vuex";

export default {
  name: "SinglePurchase",

  data: () => ({
    chargeYear: "",
    chargeNumber: "",
    assignee: null,
    financialAssignee: null,
    ITAssignee: null,
    isUserManager: false,
    loading: false,
    loadingAttachment: false,
    currentFile: null,
    currentFiles: [],
    attFilename: null,
    attFilenames: [],
    comment: null,
    event: null,
    updating: false,
    method: null,
    myFSM: null,
    purchase: null,
    purchaseDescription: "",
    reducedHistory: [],
    reducedReversedHistory: [],
    currentState: null,
    currentHistory: null,
    currentMethod: null,
    currentMethodText: null,
    detailsDialog: false,
    updateDialog: false,
    globalStep: null,
    attachments: [],
    selectedMethod: null,
    sendEvent: null,
    sendMethod: null,
    timerDate: null,
    dateDialog: false,
    addChargeDialog: false,
    notifyGroup: false,
    notifyUsers: true,
    groupNotifyLabel: "",
    userNotifyLabel: "",
    snackbar: false,
    snackbarColor: "",
    errorText: "",
    timeout: 5000,
    error: false,
    snoozeDialog: false,
    removeAssignee: false,
    snoozeDate: null,
    snoozeID: null,
    currentGroupUsers: [],
    currentExtGroupUsers: [],
    currentGroupName: null,
    selectedGroupUsers: [],
    allOperators: [],
    selectedExtGroupUsers: [],
    allGroupUsers: false,
    toggleToList: false,
    selectedExtUser: null,
    selectedExtUsers: [],
    invalidCharge: true,
    assets: [],
  }),
  computed: {
    ...mapState(["userInitials", "userRole"]),
    computedEvent() {
      return this.myFSM.context.method === "amazon"
        ? "waitForAmazonApproval"
        : this.myFSM.context.method === "quadro"
        ? "waitForQuadroApproval"
        : this.myFSM.context.method === "direct"
        ? "waitForDirectApproval"
        : this.myFSM.context.method === "consip"
        ? "waitForCONSIPApproval"
        : this.myFSM.context.method === "rdo"
        ? "waitForRDOApproval"
        : "";
    },
  },
  created: function () {
    this.loading = true;
    this.loadPurchase();
  },
  watch: {
    assignee: function () {
      this.assigneeChanged();
    },
    chargeYear: function (newVal, oldVal) {
      let year = parseInt(this.chargeYear);
      let number = parseInt(this.chargeNumber);
      if (
        Number.isInteger(year) &&
        year >= 1970 &&
        year <= (new Date().getFullYear())+1 &&
        Number.isInteger(number) &&
        number > 0
      ) {
        this.invalidCharge = false;
      } else {
        this.invalidCharge = true;
      }
    },
    chargeNumber: function (newVal, oldVal) {
      let year = parseInt(this.chargeYear);
      let number = parseInt(this.chargeNumber);
      if (
        Number.isInteger(year) &&
        year >= 1970 &&
        year <= (new Date().getFullYear())+1 &&
        Number.isInteger(number) &&
        number > 0
      ) {
        this.invalidCharge = false;
      } else {
        this.invalidCharge = true;
      }
    },
  },
  methods: {
    deleteChip(index, text) {
      this.currentFiles.splice(index, 1);
    },
    autocompleteFilter(item, queryText, itemText) {
      // console.log(item)
      // console.log(queryText)
      let result =
        item.COGNOME.toLocaleLowerCase().indexOf(
          queryText.toLocaleLowerCase()
        ) >= 0 ||
        item.NOME.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >=
          0;
      return result;
    },
    addExtUser() {
      // console.log(this.selectedExtUser)
      // console.log(this.currentGroupUsers)
      let alreadyThere = false;
      for (let user of this.currentExtGroupUsers) {
        if (user.MATRICOLA === this.selectedExtUser.MATRICOLA) {
          alreadyThere = true;
        }
      }
      for (let user of this.currentGroupUsers) {
        if (user.MATRICOLA === this.selectedExtUser.MATRICOLA) {
          alreadyThere = true;
        }
      }

      if (!alreadyThere) {
        this.currentExtGroupUsers.push(this.selectedExtUser);
        this.selectedExtUsers.push({
          matricola: this.selectedExtUser.MATRICOLA,
          fullname:
            this.selectedExtUser.COGNOME + " " + this.selectedExtUser.NOME,
        });
      }
      this.selectedExtUser = [];
    },
    notifiedGroupUsersList(usersList) {
      let str = "(";
      let first = true;
      for (let user of usersList) {
        if (!first) {
          str += ", ";
        }
        first = false;
        str += user.fullname;
      }
      str += ")";
      return str;
    },
    selectAllGroupUsers(state) {
      this.allGroupUsers = this.financialAssignee
        ? this.myFSM.states[state].meta.financial
          ? false
          : true
        : true;
      this.currentGroupUsers = this.myFSM.states[state].ownersGroupUsers;

      this.selectedGroupUsers = [];
      for (let user of this.myFSM.states[state].ownersGroupUsers) {
        if (
          !this.financialAssignee ||
          this.financialAssignee.MATRICOLA === user.MATRICOLA
        ) {
          this.selectedGroupUsers.push({
            matricola: user.MATRICOLA,
            fullname: user.COGNOME + " " + user.NOME,
          });
        } else if (!this.myFSM.states[state].meta.financial) {
          this.selectedGroupUsers.push({
            matricola: user.MATRICOLA,
            fullname: user.COGNOME + " " + user.NOME,
          });
        }
      }
    },
    evaluateAllGroupUsers() {
      if (this.allGroupUsers) {
        // this.selectedGroupUsers = this.currentGroupUsers;
        this.selectedGroupUsers = [];
        for (let user of this.currentGroupUsers) {
          this.selectedGroupUsers.push({
            matricola: user.MATRICOLA,
            fullname: user.COGNOME + " " + user.NOME,
          });
        }
      } else {
        this.selectedGroupUsers = [];
      }
    },
    downloadAttachment(attachment) {
      const headers = {
        authorization: "Bearer " + this.$store.state.authJwt,
      };

      this.$http
        .get(
          process.env.VUE_APP_PATRIMONIO_API +
            "/purchases/attachment/" +
            attachment,
          {
            // This is very important to avoi white page pdf
            responseType: "arraybuffer",
            headers,
          }
        )
        .then((response) => {
          let contentType = response.headers["content-type"];
          let blob = new Blob([response.data], { type: contentType });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = this.attachmentFilename(attachment);
          link.click();
        })
        .catch((error) => {
          // handle error
          console.log(error);
          // this.permSnackbar = true;
          // this.snackbarColor = "error";
          // this.errorText = error.response.data;
          // this.timeout= -1;
        });
    },
    deleteAttachment(attachment) {
      this.loadingAttachment = true;
      const headers = {
        authorization: "Bearer " + this.$store.state.authJwt,
      };

      this.$http
        .put(
          process.env.VUE_APP_PATRIMONIO_API +
            "/purchases/attachment/delete/" +
            attachment,
          {},
          {
            // This is very important to avoi white page pdf
            headers,
          }
        )
        .then((response) => {
          // this.loadPurchase();
          this.loadingAttachment = false;
        })
        .catch((error) => {
          // handle error
          console.log(error);
          this.loadingAttachment = false;
        });
    },
    restoreAttachment(attachment) {
      this.loadingAttachment = true;
      const headers = {
        authorization: "Bearer " + this.$store.state.authJwt,
      };

      this.$http
        .put(
          process.env.VUE_APP_PATRIMONIO_API +
            "/purchases/attachment/restore/" +
            attachment,
          {},
          {
            // This is very important to avoi white page pdf
            headers,
          }
        )
        .then((response) => {
          // this.loadPurchase();
          this.loadingAttachment = false;
        })
        .catch((error) => {
          // handle error
          console.log(error);
          this.loadingAttachment = false;
        });
    },
    starAttachment(attachment) {
      this.loadingAttachment = true;
      const headers = {
        authorization: "Bearer " + this.$store.state.authJwt,
      };

      this.$http
        .put(
          process.env.VUE_APP_PATRIMONIO_API +
            "/purchases/attachment/star/" +
            attachment,
          {},
          {
            // This is very important to avoi white page pdf
            headers,
          }
        )
        .then((response) => {
          // this.loadPurchase();
          this.loadingAttachment = false;
          this.loading = false;
        })
        .catch((error) => {
          // handle error
          console.log(error);
          this.loadingAttachment = false;
          this.loading = false;
        });
    },
    unstarAttachment(attachment) {
      this.loadingAttachment = true;
      const headers = {
        authorization: "Bearer " + this.$store.state.authJwt,
      };

      this.$http
        .put(
          process.env.VUE_APP_PATRIMONIO_API +
            "/purchases/attachment/unstar/" +
            attachment,
          {},
          {
            // This is very important to avoi white page pdf
            headers,
          }
        )
        .then((response) => {
          // this.loadPurchase();
          this.loadingAttachment = false;
          this.loading = false;
        })
        .catch((error) => {
          // handle error
          console.log(error);
          this.loadingAttachment = false;
          this.loading = false;
        });
    },
    deleteCharge(year, number) {
      const headers = {
        authorization: "Bearer " + this.$store.state.authJwt,
      };

      let component = this;

      this.$http
        .delete(
          process.env.VUE_APP_PATRIMONIO_API +
            "/purchases/charge/" +
            this.purchase.ID +
            "/" +
            year +
            "/" +
            number,
          {
            // This is very important to avoi white page pdf
            headers,
          }
        )
        .then((response) => {
          this.loadPurchase();
          // this.loadingAttachment = false;
        })
        .catch((error) => {
          // handle error
          component.addChargeDialog = false;
          component.errorText = "Errore in aggiornamento acquisto";
          component.snackbarColor = "red";
          component.snackbar = true;

          console.log(error);
        });
    },
    addCharge(year, number) {
      const headers = {
        authorization: "Bearer " + this.$store.state.authJwt,
      };
      let component = this;

      component.$http
        .put(
          process.env.VUE_APP_PATRIMONIO_API +
            "/purchases/charge/" +
            component.purchase.ID +
            "/" +
            year +
            "/" +
            number,
          {},
          {
            // This is very important to avoi white page pdf
            headers,
          }
        )
        .then((response) => {
          component.loadPurchase();
          // this.loadingAttachment = false;
          component.addChargeDialog = false;
        })
        .catch((error) => {
          // handle error
          component.addChargeDialog = false;
          component.errorText = "Errore in aggiornamento acquisto";
          component.snackbarColor = "red";
          component.snackbar = true;

          console.log(error);
        });
    },
    getEventText(eventName) {
      // console.log(eventName);
      // console.log(this.myFSM.states)

      for (const [key, state] of Object.entries(this.myFSM.states)) {
        if (state.meta.buttons) {
          for (let button of state.meta.buttons) {
            if (button.event && button.event === eventName) {
              return button.description;
            }
          }
        }
        if (state.meta.selection) {
          for (let sel of state.meta.selection) {
            if (sel.event && sel.event === eventName) {
              return sel.description;
            }
          }
        }
        if (state.on) {
          for (const [eventKey, stateEvent] of Object.entries(state.on)) {
            if (eventKey === eventName) {
              // Scan buttons for empty event
              if (state.meta.buttons) {
                for (let button of state.meta.buttons) {
                  if (!button.event) {
                    return button.text;
                  }
                }
              }
            }
          }
        }
      }
      return eventName;
    },
    attachmentFilename(attachment) {
      for (let att of this.attachments) {
        if (att.id === attachment) {
          return att.ORIGINAL_FILENAME;
        }
      }
      return "NONE";
    },
    attachmentDeleted(attachment) {
      for (let att of this.attachments) {
        if (att.id === attachment) {
          return att.deleted;
        }
      }
      return false;
    },
    async checkIsUserManager(group) {
      if (this.$store.state.userRole === "admin") {
        this.isUserManager = true;
      }
      try {
        let response = await this.$http.get(
          process.env.VUE_APP_PATRIMONIO_API + "/users/getgroupbyname/" + group,
          {
            headers: {
              authorization: "Bearer " + this.$store.state.authJwt,
            },
          }
        );
        for (let user of response.data.Users) {
          if (user.MATRICOLA === this.$store.state.userMatricola) {
            this.isUserManager = true;
            break;
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    async loadPurchase() {
      // this.loading = true;
      // this.loadingAttachment = true;
      this.$http
        .get(
          process.env.VUE_APP_PATRIMONIO_API +
            "/purchases/" +
            this.$route.params.id,
          {
            headers: {
              authorization: "Bearer " + this.$store.state.authJwt,
            },
          }
        )
        .then((response) => {
          this.myFSM = response.data.fsm;
          let groups = [];
          for (let [index, state] of Object.entries(this.myFSM.states)) {
            if (!groups.includes(state.meta.ownersGroup))
              groups.push(state.meta.ownersGroup);
          }
          this.allOperators = [];
          for (let group of groups) {
            this.$http
              .get(
                process.env.VUE_APP_PATRIMONIO_API +
                  "/users/getgroupbyname/" +
                  group,
                {
                  headers: {
                    authorization: "Bearer " + this.$store.state.authJwt,
                  },
                }
              )
              .then((groupResponse) => {
                for (let user of groupResponse.data.Users) {
                  let alreadyThere = false;
                  for (let alreadyUser of this.allOperators) {
                    if (user.MATRICOLA === alreadyUser.MATRICOLA) {
                      alreadyThere = true;
                      break;
                    }
                  }
                  if (!alreadyThere) {
                    this.allOperators.push(user);
                  }
                }
              })
              .catch((err) => {});
          }
          this.financialAssignee = response.data.financialAssignee;
          this.ITAssignee = response.data.ITAssignee;
          this.sendEvent = null;
          this.purchase = response.data.purchase;

          this.assets = [];
          for (let charge of this.purchase.Charges) {
            let query = {
              searchType: "and",
              type: "group",
              children: [
                {
                  field: "annocarico",
                  operator: "eq",
                  value: charge.YEAR,
                  fieldText: "Anno di carico",
                  operatorText: "è uguale a",
                  valueText: charge.YEAR,
                },
                {
                  field: "buonocarico",
                  operator: "eq",
                  value: charge.NUMBER,
                  fieldText: "Numero buono carico",
                  operatorText: "è uguale a",
                  valueText: charge.NUMBER,
                },
              ],
            };
            this.$http
              .post(
                process.env.VUE_APP_PATRIMONIO_API + "/assets/search",
                query,
                {
                  headers: {
                    authorization: "Bearer " + this.$store.state.authJwt,
                  },
                }
              )
              .then((response) => {
                for (let asset of response.data.assets) {
                  this.assets.push(asset);
                }
              })
              .catch((err) => {
                console.log(err.response.data.error);
                if (
                  err.response &&
                  err.response.data &&
                  err.response.data.error
                ) {
                  this.errorText = err.response.data.error;
                } else {
                  this.errorText = err;
                }
                this.snackbarColor = "error";
                this.permSnackbar = true;
              });
          }
          if (this.purchase.PurchaseHistories.length > 0) {
            this.currentState =
              this.purchase.PurchaseHistories[
                this.purchase.PurchaseHistories.length - 1
              ].state;
            this.currentHistory =
              this.purchase.PurchaseHistories[
                this.purchase.PurchaseHistories.length - 1
              ];
            this.currentMethod =
              this.purchase.PurchaseHistories[
                this.purchase.PurchaseHistories.length - 1
              ].method;
            this.currentMethodText =
              this.myFSM.context.methods[
                this.purchase.PurchaseHistories[
                  this.purchase.PurchaseHistories.length - 1
                ].method
              ];
            this.currentGroupName =
              this.myFSM.states[this.currentHistory.state].meta.ownersGroup;
            this.$http
              .get(
                process.env.VUE_APP_PATRIMONIO_API +
                  "/users/getgroupbyname/" +
                  this.currentGroupName,
                {
                  headers: {
                    authorization: "Bearer " + this.$store.state.authJwt,
                  },
                }
              )
              .then((response) => {
                this.currentGroupUsers = response.data.Users;
              })
              .catch((err) => {});
          }
          this.reducedHistory = this.reduceHistory(
            this.purchase.PurchaseHistories
          );
          this.reducedReversedHistory = this.reducedHistory.slice().reverse();
          this.attachments = this.purchase.Attachments.reverse();
          for (let attachment of this.attachments) {
            for (let step of this.purchase.PurchaseHistories) {
              if (step.attachment === attachment.id) {
                attachment.state = step;
                break;
              }
            }
          }
          this.purchaseDescription = this.purchase.description;
          this.userNotifyLabel = "Notifica richiedente/i: ";
          let firstUser = true;
          for (let user of this.purchase.Users) {
            if (!firstUser) {
              this.userNotifyLabel += ", ";
            }
            firstUser = false;
            this.userNotifyLabel += user.COGNOME + " " + user.NOME;
          }
          this.loading = false;
          this.loadingAttachment = false;
          this.error = false;
          // console.log(this.myFSM);
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.data && err.response.data.messageIT) {
              this.errorText = err.response.data.messageIT;
            } else if (err.response.data && err.response.data.message) {
              this.errorText = err.response.data.message;
            } else if (err.response.data && err.response.data.errorIT) {
              this.errorText = err.response.data.errorIT;
            } else if (err.response.data && err.response.data.error) {
              this.errorText = err.response.data.error;
            } else {
              this.errorText = err.response.statusText;
            }
            // console.log(err.response)
            this.snackbarColor = "red";
            this.snackbar = true;
          } else if (err.request) {
            this.errorText = "Errore in caricamento acquisti";
            this.snackbarColor = "red";
            this.snackbar = true;
          } else {
            console.log(err);
          }
          this.error = true;
          this.loading = false;
          this.loadingAttachment = false;
        });
    },
    assigneeChanged() {
      if (!this.assignee) {
        return;
      }
      this.allGroupUsers = false;
      // console.log(this.currentGroupUsers)
      this.selectedGroupUsers = [];
      // this.selectedGroupUsers.push(this.assignee);
      // for (let user of this.currentGroupUsers) {
      //   if (user.MATRICOLA === this.assignee) {
      //     this.selectedGroupUsers.push({
      //       matricola: user.MATRICOLA,
      //       fullname: user.COGNOME + " " + user.NOME,
      //     });
      //   }
      // }
    },
    reduceHistory(history) {
      let reduced = [];
      let prevStatus = null;
      for (let step of history) {
        if (prevStatus) {
          if (prevStatus === step.state) {
            // TODO: Expand current status
            reduced[reduced.length - 1].insideSteps.push(step);
          } else {
            reduced.push(step);
            reduced[reduced.length - 1].insideSteps = [];
            reduced[reduced.length - 1].insideSteps.push(step);
          }
        } else {
          reduced.push(step);
          reduced[reduced.length - 1].insideSteps = [];
          reduced[reduced.length - 1].insideSteps.push(step);
        }
        prevStatus = reduced[reduced.length - 1].state;
      }
      for (let step of reduced) {
        let reversed = step.insideSteps.slice();
        step.insideStepsReversed = reversed.reverse();
      }

      return reduced;
    },
    formatStep(step) {
      return moment(step.date)
        .tz("Europe/Rome")
        .locale("IT")
        .format("D MMMM YYYY, H:mm:ss");
    },
    perfectDate(date) {
      return moment(date)
        .tz("Europe/Rome")
        .locale("IT")
        .format("YYYY-MM-DD HH:mm:ss");
    },
    perfectDateOnly(date) {
      return moment(date).tz("Europe/Rome").locale("IT").format("YYYY-MM-DD");
    },
    perfectDateNow() {
      return moment().tz("Europe/Rome").locale("IT").format("YYYY-MM-DD");
    },
    formatDate(date) {
      return !date
        ? null
        : moment(date).tz("Europe/Rome").locale("IT").format("D MMMM YYYY");
    },
    formatTime(date) {
      return !date
        ? null
        : moment(date)
            .tz("Europe/Rome")
            .locale("IT")
            .format("D MMMM YYYY, H:mm:ss");
    },
    needEvent() {
      if (!this.myFSM || !this.sendEvent || !this.currentState) {
        return false;
      }
      if (
        this.myFSM.states[
          this.myFSM.states[this.currentState].on[this.sendEvent].target
        ].meta.timer &&
        this.sendEvent &&
        (!this.timerDate || (!this.purchase.CIG && this.myFSM.states[this.currentState].meta.insertCIG))
      ) {
        return true;
      }
      return false;
    },
    updatePurchase(currentPurchase, event, method) {
      this.updating = true;
      let formData = new FormData();
      var component = this;
      if (this.currentFile && this.currentFile !== "") {
        formData.append("file", this.currentFile);
      }
      if (this.currentFiles && this.currentFiles.length > 0) {
        for (let [index, thisfile] of this.currentFiles.entries())
          formData.append("file" + index, thisfile);
      }
      if (method && method !== "") {
        formData.append("method", method);
      }
      if (event && event !== "") {
        formData.append("event", event);
      }
      if (this.purchase.CIG && this.purchase.CIG !== "") {
        formData.append("CIG", this.purchase.CIG);
      }
      // TODO: distinguish between financial and IT assignee
      if (this.assignee && this.assignee !== "") {
        formData.append("assignee", this.assignee);
        this.snackbarColor = "green";
        this.errorText = "Assegnazione completata";
        this.timeout = "1500";
        this.snackbar = true;
      } else if (this.removeAssignee) {
        formData.append("removeAssignee", true);
      }
      if (this.notifyGroup) {
        formData.append("notifyGroup", this.notifyGroup);
      }

      if (
        this.selectedGroupUsers.length > 0 ||
        this.selectedExtUsers.length > 0
      ) {
        formData.append(
          "notifyGroupUsers",
          JSON.stringify(this.selectedGroupUsers.concat(this.selectedExtUsers))
        );
      }
      if (this.notifyUsers) {
        formData.append("notifyUsers", this.notifyUsers);
      }
      if (this.comment && this.comment !== "") {
        formData.append("comment", this.comment);
      }
      if (this.timerDate && this.timerDate !== "") {
        formData.append(
          "reminder",
          this.perfectDateOnly(this.timerDate) + " 06:00:00"
        );
      }
      formData.append("purchase", currentPurchase);
      this.$http
        .put(
          process.env.VUE_APP_PATRIMONIO_API + "/purchases/" + currentPurchase,
          formData,
          {
            headers: {
              authorization: "Bearer " + this.$store.state.authJwt,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(function () {
          component.loadPurchase();
          component.attFilename = null;
          component.attFilenames = [];
          component.comment = "";
          component.selectedMethod = null;
          component.updating = false;
          component.updateDialog = false;
          component.loading = false;
          component.error = false;
        })
        .catch(function (err) {
          if (err.response) {
            if (err.response.data && err.response.data.messageIT) {
              component.errorText = err.response.data.messageIT;
            } else if (err.response.data && err.response.data.message) {
              component.errorText = err.response.data.message;
            } else {
              component.errorText = err.response.statusText;
            }
            component.snackbarColor = "red";
            component.snackbar = true;
          } else if (err.request) {
            component.errorText = "Errore in aggiornamento acquisto";
            component.snackbarColor = "red";
            component.snackbar = true;
          } else {
            console.log(err);
          }
          component.error = true;
          component.loading = false;
        });
    },
    snooze(currentPurchase) {
      this.updating = true;
      let formData = new FormData();
      var component = this;
      if (this.snoozeDate && this.snoozeDate !== "") {
        formData.append("snooze", this.perfectDateOnly(this.snoozeDate));
      }
      formData.append("purchase", currentPurchase);
      this.$http
        .put(
          process.env.VUE_APP_PATRIMONIO_API + "/purchases/" + currentPurchase,
          formData,
          {
            headers: {
              authorization: "Bearer " + this.$store.state.authJwt,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(function () {
          component.loadPurchase();
          component.attFilename = null;
          component.attFilenames = [];
          component.comment = "";
          component.selectedMethod = null;
          component.updating = false;
          component.updateDialog = false;
          component.loading = false;
          component.error = false;
        })
        .catch(function (err) {
          if (err.response) {
            if (err.response.data && err.response.data.messageIT) {
              component.errorText = err.response.data.messageIT;
            } else if (err.response.data && err.response.data.message) {
              component.errorText = err.response.data.message;
            } else {
              component.errorText = err.response.statusText;
            }
            component.snackbarColor = "red";
            component.snackbar = true;
          } else if (err.request) {
            component.errorText = "Errore in aggiornamento acquisto";
            component.snackbarColor = "red";
            component.snackbar = true;
          } else {
            console.log(err);
          }
          component.error = true;
          component.loading = false;
        });
    },
    setFile(file) {
      this.currentFile = file;
      // this.currentFiles.push(file);
    },

    // method to change view from Timeline to List and viceversa
    toggleList() {
      this.toggleToList = !this.toggleToList;
    },
  },
  mounted() {
    // enable using draggable dialogs
    this.activateMultipleDraggableDialogs();
  },
};
</script>
<style scoped>
.theme--light.v-card .steptitle {
  color: #ffffff;
  height: 30px;
  font-size: 11px;
  text-transform: uppercase;
}
.v-expansion-panel .v-expansion-panel--active .v-item--active {
  max-height: 380px;
}
.attachmentlist {
  overflow-y: auto;
  max-height: 400px;
}

</style>